import {
  extendableComponent,
  responsiveVal,
  Row,
} from '@graphcommerce/next-ui'
import React from 'react'
import {ImageLabelSwiper} from '../GraphCMS/RowLinks/variant'

export type GoogleReviewsProps = {
  reviews: Array<GoogleReviewType>
}

export type GoogleReviewType = {
  author_name: string
  profile_photo_url: string
  rating: number
  text: string
}

const name = 'ProductSpecs' as const
const parts = ['root', 'specs', 'options'] as const
const { classes } = extendableComponent(name, parts)

export function GoogleReviews(props: GoogleReviewsProps) {
  const { reviews } = props

  const pageLinks = reviews.map((review, index) => {
    const max = 5

    return {
      id: index.toString(),
      title: '',
      url: '',
      description: {
        raw: {
          children: [
            {
              type: 'paragraph',
              children: [{
                type: 'bold',
                children: [{
                  text: `${review.rating}/${max}`
                }]
              }]
            },
            {
              type: 'heading-four',
              children: [{
                text: review.author_name
              }]
            },
            {
              type: 'paragraph',
              children: [{
                text: review.text
              }]
            },
          ]
        }
      }
    }
  })

  return (
    <Row
      className='GoogleReviews-root'
      sx={(theme) => ({
        '& ': {
          backgroundColor: '#000',
          maxWidth: 'unset',
          paddingLeft: 0,
          paddingRight: 0,
          marginTop: `calc(${theme.spacings.xl} * -1)`,
          marginBottom: 0,
          paddingTop: theme.spacings.xs,
          paddingBottom: theme.spacings.xs,
          position: 'relative',
        },
        '&:before': {
          content: '" "',
          display: 'block',
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          opacity: '0.4',
          backgroundImage: 'url(/images/bg-reviews.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          overflow: 'hidden',
        },
        '& > .RowLinks-root > h2': {
          color: theme.palette.primary.main,
          typography: theme.typography.h1,
          fontWeight: theme.typography.fontWeightRegular,
          marginTop: theme.spacings.md,
          marginBottom: theme.spacings.lg,
        },
        '& .Scroller-root .MuiBox-root': {
          textAlign: 'left',
          width: responsiveVal(260, 450),
          maxWidth: responsiveVal(260, 450),
        },
        '& .Scroller-root .MuiBox-root h4': {
          color: theme.palette.primary.main,
          fontWeight: theme.typography.fontWeightRegular,
          marginTop: 0,
          marginBottom: theme.spacings.xs
        },
        '& .Scroller-root .MuiBox-root h3 + p': {
          color: theme.palette.primary.main,
          fontWeight: theme.typography.fontWeightRegular,
          marginBottom: 0,
          display: 'flex',
          gap: 1,
          alignItems: 'normal',
        },
        '& .Scroller-root .MuiBox-root h3 + p strong': {
          fontWeight: theme.typography.fontWeightRegular,
        },
        '& .Scroller-root .MuiBox-root h3 + p:before': {
          content: '""',
          backgroundImage: 'url(/images/icon-star.svg)',
          backgroundSize: '100%',
          width: '24px',
          height: '24px'
        },
        '& .Scroller-root .MuiBox-root h4 + p': {
          color: '#fff',
        }
      })}
    >
        <ImageLabelSwiper title='Vuestras opiniones'
                          pageLinks={pageLinks}
        />
    </Row>
  )
}
